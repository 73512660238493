.slider {
    position: relative;
    width: 100%;
    height: 4px;
    background-color: #04080266;
  }
  
  .slider__range {
    position: absolute;
    background-color: #007bff;
    height: 100%;
    border-radius: 10px;
  }
  
  .slider__input {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: none;
    pointer-events: none;
    -webkit-appearance: none;
    appearance: none;
  }
  
  .slider__input::-webkit-slider-thumb {
    pointer-events: all;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #007bff;
    box-shadow: 0 0 1px 1px #007bff;
    -webkit-appearance: none;
    appearance: none;
  }
  
  .slider__input::-moz-range-thumb {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #007bff;
    box-shadow: 0 0 1px 1px #007bff;
  }
  
  .slider__mark {
    position: absolute;
    top: 20px;
    font-size: 12px;
    color: #dee2e6;
  }
  
  .slider__left-value,
  .slider__right-value {
    color: #dee2e6;
    font-size: 12px;
    margin-top: 20px;
    position: absolute;
  }
  
  .slider__left-value {
    left: 6px;
  }
  
  .slider__right-value {
    right: -4px;
  }